<template>
	<div
		class="flex-column full-height bg-white position-relative"
	>

		<div
			v-if="!user.virtual_uid"
			class="full-height justify-center items-center"
		>
			<div class="bg-white pa-20-10 text-center size-px-16 font-weight-bold width-100 radius-20">설정된 가상계좌가 없습니다. <br/> <br/> 관리자에게 문의하세요</div>
		</div>
		<template
			v-else
		>
			<div
				v-if="item_wallet.uid"
				class="main-amount pa-10 flex-row justify-space-between items-center"
			>
				<div class="flex-1 font-weight-bold"></div>
				<div class="flex-3">{{ item_wallet.balance | makeComma }}원</div>

				<div class="flex-1 font-weight-bold">
					<button
						class="flex-row justify-center items-center radius-20 bg-blue mr-10"
						style="width: 60px; height: 32px"
					><v-icon class="color-white">mdi-export</v-icon></button>
				</div>
			</div>
			<div
				v-else
				class="main-amount flex-row justify-space-between items-center ptb-20"
			>
				<div class="flex-1 font-weight-bold"></div>
				<div class="flex-3">지갑 만들기</div>

				<div class="flex-1 font-weight-bold">
					<button
						@click="onCreateWallet"
						class="flex-row justify-center items-center radius-20 bg-blue mr-10"
						style="width: 60px; height: 32px"
					><v-icon class="color-white">mdi-wallet</v-icon></button>
				</div>
			</div>
			<div class="text-center under-line bg-eee">
				<h5>{{ $date.getToday('-') }}</h5>
			</div>
			<div
				class="pa-10 full-height overflow-y-auto"
			>
				<div
					v-if="user.agency_type == 'distributor'"
					class="under-line pb-10"
				>
					<h6>가상 계좌 잔고</h6>
					<div class="size-px-16 flex-row justify-space-between items-center">
						<div class="items-center justify-space-between">
							<div class="mr-10">{{ item_merchant.balanceTotAmt | makeComma }}원</div>
							<button
								@click="getData"
							><v-icon class="color-blue">mdi-refresh mdi-flip-h</v-icon></button>
						</div>

						<div>
							<button
								@click="onMerchantHistory"
								class="btn-inline btn-success mr-10"
							>내역</button>

							<button
								@click="onMerchantWithdrawal"
								class="btn-inline btn-primary mr-10"
								:disabled="is_sms_confirm"
							>출금</button>
						</div>
					</div>
				</div>
				<div
					class="under-line pb-10"
				>
					<h6>가맹점 정산 금액</h6>
					<div class="size-px-16 text-right"> {{ item.settlement_balance | makeComma }}원</div>
				</div>
				<div
					class="under-line pb-10"
				>
					<h6>가맹점 지급 예정 금액</h6>
					<div class="size-px-16 text-right"> {{ item.deposit_balance | makeComma }}원</div>
				</div>
			</div>
		</template>

		<PopupLayer
			v-if="is_on_merchant_history"

			@cancel="is_on_merchant_history = false"
		>
			<template
				v-slot:body
			>
				<div class="pa-10 bg-white radius-top-20">
					<div class="bg-popup-title flex-row justify-space-between items-center">
						<h4>가상계좌 최근 내역</h4>
						<v-icon
							@click="is_on_merchant_history = false"
						>mdi-close-circle</v-icon>
					</div>

					<hr class="under-line ">

					<div class="height-min-480 overflow-y-auto size-px-14" style="max-height: 480px">
						<ul
							class="pa-10 bg-gray-light"
						>
							<li
								v-for="(history, h_index) in list_history"
								:key="'history_' + h_index"
								class="pa-20 under-line bg-white mb-20 radius-20 box-shadow"
							>
								<div class="justify-space-between under-line-dashed pb-10">
									<div>{{ history.wDate}}</div>
									<div :class="history.status_color">{{ history.status_name }}</div>
								</div>
								<div class="mt-10 justify-space-between">
									<div :class="history.type_color">{{ history.type_name}}</div>
									<div>{{ history.amount | makeComma}}원</div>
								</div>
								<div class="mt-10 text-right">
									<div>{{ history.bank_name }} {{ history.bank_account }} {{ history.bank_holder }}</div>
									<div>{{ history.bank_depositor }}</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</template>
		</PopupLayer>

		<MerchantWithdrawal
			v-if="is_on_merchant_withdrawal"
			:user="user"
			:item_merchant="item_merchant"
			:type="1"

			@click="is_on_merchant_withdrawal = false; getData();"
			@cancel="is_on_merchant_withdrawal = false"
		></MerchantWithdrawal>

		<PopupConfirm
			v-if="is_on_create_wallet"
			@cancel="is_on_create_wallet = false"
			@click="postCreateWallet"
		>
			<template
				v-slot:title
			>지갑 생성</template>
			<template
				v-slot:main-txt
			>출금 수수료 지갑을 생성합니다</template>
		</PopupConfirm>
	</div>
</template>

<script>

import PopupLayer from "../Layout/PopupLayer";
import MerchantWithdrawal from "./MerchantWithdrawal";
import PopupConfirm from "../Layout/PopupConfirm";
export default{
	name: 'IndexMerchant'
	, components: {PopupConfirm, MerchantWithdrawal, PopupLayer}
	, props: ['user']
	,data: function(){
		return {
			program: {
				name: '지갑'
				, title: true
				, bottom: true
				, top: false
			}
			, item: {
				user_balance: 0
				, settlement_balance: 0
				, deposit_balance: 0
			}
			, item_merchant: {
				balanceTotAmt: 0
			}
			, is_on_merchant_history: false
			, is_on_merchant_withdrawal: false
			, item_withdrawal: {
				amount: ''
				, bank: ''
				, account: ''
				, holder: ''
				, is_on_sms_confirm: false
				, is_sms_confirm: false
				, depositor: ''
			}
			, item_search_history: {
				page: 1
				, list_cnt: 10
				, sDate: ''
				, eDate: ''
			}
			, items_history: []
			, is_on_create_wallet: false
			, is_holder: false
			, item_holder: {}
			, item_wallet: {}
		}
	}
	,computed: {
		is_to_merchant_withdrawal: function(){
			let t = true
			if(this.item_withdrawal.bank && this.item_withdrawal.account && this.item_withdrawal.holder && this.item_withdrawal.amount && this.item_withdrawal.depositor){
				if(this.item_merchant.balanceTotAmt >= Number(this.item_withdrawal.amount) + Number(this.user.virtual_fee)){
					t = false
				}
			}

			return t
		}
		, list_history: function(){
			return this.items_history.filter( (item) => {

				if(item.send_type == 1){
					item.type_name = '출금'
					item.type_color = 'color-red'
				}else{
					item.type_name = '입금'
					item.type_color = 'color-blue'
				}
				if(item.status == 1){
					item.status_name = '완료'
					item.status_color = 'color-green'
				}else{
					item.status_name = '-'
				}

				this.user.bank_list.filter( (bank) => {
					if(bank.bankId == item.bank_code){
						item.bank_name = bank.bankName
					}
				})
				return item
			})
		}
		, is_sms_confirm: function(){
			let t = true
			if(this.user.sms_auth_uid){
				t = false
			}
			return t
		}
		, txt_amount: function(){
			let t = 0
			if(this.item_withdrawal.amount){
				t = this.$common.geKoreanNumber((this.item_withdrawal.amount + '').replaceAll(',', ''))
			}
			return t
		}
		, is_payment: function(){
			let t = false
			if(this.user.is_payment){
				t = true
			}
			return t
		}
	}
	,methods: {
		getData: async function(){
			this.$bus.$emit('on', true)
			try {
				const result = await this.$Axios({
					method: 'get'
					, url: 'member/getMainInfo'
					, data: this.search
				})

				if (result.success) {
					this.item_merchant = result.data.merchant_balance
					this.item_wallet = result.data.wallet_info
					this.user.bank_list = result.data.bank_list
					this.item.deposit_balance = result.data.deposit_balance
					this.item.settlement_balance = result.data.settlement_balance
				} else {
					this.$bus.$emit('notify', {type: 'error', message: result.message})
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onMerchantHistory: function(){
			this.getMerchantHistory()
		}
		, onMerchantWithdrawal: async function(){
			await this.getData()
			this.is_on_merchant_withdrawal = true
		}
		, getMerchantHistory: async function(){
			this.$bus.$emit('on', true)
			try {
				const result = await this.$Axios({
					method: 'get'
					, url: this.$api_url.api_path.get_merchant_history
					, data: this.item_search_history
				})

				if (result.success) {
					this.items_history = result.data
					this.is_on_merchant_history = true
				} else {
					this.$bus.$emit('notify', {type: 'error', message: result.message})
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postMerchantWithdrawal: async function(){
			this.$bus.$emit('on', true)
			try {
				const result = await this.$Axios({
					method: 'post'
					, url: this.$api_url.api_path.post_merchant_withdrawal
					, data: this.item_withdrawal
				})

				if (result.success) {
					await this.getData()
					this.is_on_merchant_withdrawal = false
				} else {
					this.$bus.$emit('notify', {type: 'error', message: result.message})
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, onPin: function(){
			this.$bus.$off('pinCallback')

			this.$bus.$emit('onPin', {
				type: 'check'
			})

			this.$bus.$on('pinCallback', (call) => {
// console.log('pinCallback', call)
				this.$set(this.item_withdrawal, 'pin', call)
				this.postPinCheck(call)
			})
		}
		, postPinCheck: async function(data){
			try{
				this.$bus.$emit('on', true)
				let result = await this.$Axios({
					method: 'post'
					, url: this.$api_url.api_path.post_pin_check
					, data: {
						account_id: this.user.account_id
						, pin: data.pin
					}
				})
				if(result.success){
					await this.postMerchantWithdrawal()
					this.$bus.$emit('offPin')
				}else{
					throw result.message
				}
			}catch(e){
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onSmsConfirm (){
			this.$bus.$emit('onSmsConfirm')
			this.$bus.$off('smsCallback')
			this.$bus.$on('smsCallback', (item) => {
				this.$set(this.item_withdrawal, 'sms_confirm', item)
				this.postMerchantWithdrawal()
			})
		}
		, onCreateWallet: function(){
			this.is_on_create_wallet = true
		}
		, postCreateWallet: async function(){
			try{
				this.$bus.$emit('on', true)
				let result = await this.$Axios({
					method: 'post'
					, url: 'member/postCreateWallet'
					, data: {
						shop_uid: this.user.shop_uid
					}
				})
				if(result.success){
					this.$bus.$emit('notify', { type: 'success', message: result.message})
					this.is_on_create_wallet = false
					await this.getData()
				}else{
					throw result.message
				}
			}catch(e){
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postHoder: async function(){
			try{
				this.$bus.$emit('on', true)
				let result = await this.$Axios({
					method: 'post'
					, url: 'member/postHolder'
					, data: this.item_withdrawal
				})
				if(result.success){
					this.is_holder = true
					this.item_holder = result.data
				}else{
					throw result.message
				}
			}catch(e){
				this.is_holder = false
				this.item_holder = {}
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}
	,created: function(){
		this.$emit('onLoad', this.program)
		if(this.user.virtual_uid){
			this.getData()
		}
		this.$bus.$emit('onLoad', this.program)
	}
}

</script>

<style>
</style>

