<template>
	<IndexMerchant
		v-if="is_merchant"
		:user="user"
	></IndexMerchant>
	<IndexUser
		v-else
		:user="user"
	></IndexUser>
</template>

<script>

import IndexMerchant from "../Main/IndexMerchant";
import IndexUser from "../Main/IndexUser";

export default{
	name: 'WalletIndex'
	, components: {IndexUser, IndexMerchant}
	, props: ['user']
	,data: function(){
		return {
			program: {
				name: ''
				, title: ''
				, bottom: true
				, top: true
			}
		}
	}
	,computed: {
		is_merchant: function(){
			let t = false

			if(this.user.agency_type == 'distributor'){
				t = true
			}

			return t
		}
		, is_payment: function(){
			let t = false
			if(this.user.is_payment){
				t = true
			}
			return t
		}
	}
	,methods: {

	}
	,created: function(){
		// this.$emit('onLoad', this.program)
	}
}

</script>